import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// Components
import Layout from '../components/Layout';
import EnLayout from '../components/EnLayout';
import JaLayout from '../components/JaLayout';
import UnderConstrcution from '../components/UnderConstruction';

// Language
import { getPackOf } from '../lang';
const LANG_ZH = getPackOf('zh');
const LANG_EN = getPackOf('en');
const LANG_JA = getPackOf('ja');

export const query = graphql`
  query($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        disable
        title
        caseCategory
        bgImg {
          publicURL
        }
        mobBgImg {
          publicURL
        }
        caseImg {
          img {
            publicURL
          }
        }
      }
      html
    }
  }
`;

const CasePost = (props) => {
  const [lightBox, setLightbox] = useState(false);
  const [index, setIndex] = useState(0);

  const {
    data: {
      markdownRemark: {
        frontmatter: { disable, caseImg, title, caseCategory, bgImg, mobBgImg },
        html,
      },
    },
    pageContext: { langKey },
  } = props;

  const images = caseImg ? caseImg.map((el) => el.img.publicURL) : [];
  const prevIndex = (index + images.length - 1) % images.length;
  const nextIndex = (index + 1) % images.length;

  return (
    <>
      {langKey === 'zh' && (
        <Layout>
          <section id="page_bn" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
            <div className="position-relative">
              <div className="position-absolute fixed-top text-white" style={{ zIndex: '8' }}>
                <div className="container">
                  <div id="breadcrumb">
                    <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
                      <div className="">
                        <div className="col-12 pt-2">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb small text-white pt-3 pb-2 bg-js">
                              <li className="breadcrumb-item">
                                <Link to="/" style={{ color: '#fff' }}>
                                  {LANG_ZH.首頁}
                                </Link>
                              </li>
                              <li className="breadcrumb-item">
                                <Link to="/case" style={{ color: '#fff' }}>
                                  {LANG_ZH.成功案例}
                                </Link>
                              </li>
                              <li className="breadcrumb-item">{title}</li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="position-absolute fixed-bottom text-white bk-6">
                <div className="container mb-5 container position-absolute mb-5 fixed-bottom">
                  <div className="col-12 h2">{title}</div>
                  <div className="col-12">
                    {LANG_ZH.支架使用類型}：{LANG_ZH[caseCategory]}
                  </div>
                </div>
              </div>
              <img className="d-none d-md-block main_rwd" src={bgImg.publicURL} alt="background" />
              <img className="d-block d-md-none main_rwd" src={mobBgImg.publicURL} alt="background" />
            </div>
          </section>
          {disable ? (
            <UnderConstrcution />
          ) : (
            <>
              <section id="case_ps" className="py-3" style={{ backgroundColor: '#efefef' }}>
                <div className="container ">
                  <div className="col-12">
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                </div>
              </section>
              <section id="produ" className="pb-3 pb-md-5" style={{ backgroundColor: ' #efefef' }}>
                <div className="container ">
                  <div className="row p-2">
                    {images.map((el, i) => {
                      return (
                        <a key={el} className="col-6 col-md-3 col-lg-2 ">
                          <img
                            src={el}
                            alt="case"
                            className="img-fluid img-thumbnail my-2"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setLightbox(true);
                              setIndex(i);
                            }}
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </section>
              {lightBox && (
                <Lightbox
                  mainSrc={images[index]}
                  nextSrc={images[nextIndex]}
                  prevSrc={images[prevIndex]}
                  onCloseRequest={() => setLightbox(false)}
                  onMoveNextRequest={() => setIndex(nextIndex)}
                  onMovePrevRequest={() => setIndex(prevIndex)}
                />
              )}
            </>
          )}
        </Layout>
      )}
      {langKey === 'en' && (
        <EnLayout>
          <section id="page_bn" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
            <div className="position-relative">
              <div className="position-absolute fixed-top text-white" style={{ zIndex: '8' }}>
                <div className="container">
                  <div id="breadcrumb">
                    <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
                      <div className="">
                        <div className="col-12 pt-2">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb small text-white pt-3 pb-2 bg-js">
                              <li className="breadcrumb-item">
                                <Link to="/en" style={{ color: '#fff' }}>
                                  {LANG_EN.首頁}
                                </Link>
                              </li>
                              <li className="breadcrumb-item">
                                <Link to="/en/case" style={{ color: '#fff' }}>
                                  {LANG_EN.成功案例}
                                </Link>
                              </li>
                              <li className="breadcrumb-item">{title}</li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="position-absolute fixed-bottom text-white bk-6">
                <div className="container mb-5 container position-absolute mb-5 fixed-bottom">
                  <div className="col-12 h2">{title}</div>
                  <div className="col-12">
                    {LANG_EN.支架使用類型}: {LANG_EN[caseCategory]}
                  </div>
                </div>
              </div>
              <img className="d-none d-md-block main_rwd" src={bgImg.publicURL} alt="background" />
              <img className="d-block d-md-none main_rwd" src={mobBgImg.publicURL} alt="background" />
            </div>
          </section>
          {disable ? (
            <UnderConstrcution />
          ) : (
            <>
              <section id="case_ps" className="py-3" style={{ backgroundColor: '#efefef' }}>
                <div className="container ">
                  <div className="col-12">
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                </div>
              </section>
              <section id="produ" className="pb-3 pb-md-5" style={{ backgroundColor: ' #efefef' }}>
                <div className="container ">
                  <div className="row p-2">
                    {images.map((el, i) => {
                      return (
                        <a key={el} className="col-6 col-md-3 col-lg-2 ">
                          <img
                            src={el}
                            alt="case"
                            className="img-fluid img-thumbnail my-2"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setLightbox(true);
                              setIndex(i);
                            }}
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </section>
              {lightBox && (
                <Lightbox
                  mainSrc={images[index]}
                  nextSrc={images[nextIndex]}
                  prevSrc={images[prevIndex]}
                  onCloseRequest={() => setLightbox(false)}
                  onMoveNextRequest={() => setIndex(nextIndex)}
                  onMovePrevRequest={() => setIndex(prevIndex)}
                />
              )}
            </>
          )}
        </EnLayout>
      )}
      {langKey === 'ja' && (
        <JaLayout>
          <section id="page_bn" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
            <div className="position-relative">
              <div className="position-absolute fixed-top text-white" style={{ zIndex: '8' }}>
                <div className="container">
                  <div id="breadcrumb">
                    <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
                      <div className="">
                        <div className="col-12 pt-2">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb small text-white pt-3 pb-2 bg-js">
                              <li className="breadcrumb-item">
                                <Link to="/ja" style={{ color: '#fff' }}>
                                  {LANG_JA.首頁}
                                </Link>
                              </li>
                              <li className="breadcrumb-item">
                                <Link to="/ja/case" style={{ color: '#fff' }}>
                                  {LANG_JA.成功案例}
                                </Link>
                              </li>
                              <li className="breadcrumb-item">{title}</li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="position-absolute fixed-bottom text-white bk-6">
                <div className="container mb-5 container position-absolute mb-5 fixed-bottom">
                  <div className="col-12 h2">{title}</div>
                  <div className="col-12">
                    {LANG_JA.支架使用類型}：{LANG_JA[caseCategory]}
                  </div>
                </div>
              </div>
              <img className="d-none d-md-block main_rwd" src={bgImg.publicURL} alt="background" />
              <img className="d-block d-md-none main_rwd" src={mobBgImg.publicURL} alt="background" />
            </div>
          </section>
          {disable ? (
            <UnderConstrcution />
          ) : (
            <>
              <section id="case_ps" className="py-3" style={{ backgroundColor: '#efefef' }}>
                <div className="container ">
                  <div className="col-12">
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                </div>
              </section>
              <section id="produ" className="pb-3 pb-md-5" style={{ backgroundColor: ' #efefef' }}>
                <div className="container ">
                  <div className="row p-2">
                    {images.map((el, i) => {
                      return (
                        <a key={el} className="col-6 col-md-3 col-lg-2 ">
                          <img
                            src={el}
                            alt="case"
                            className="img-fluid img-thumbnail my-2"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setLightbox(true);
                              setIndex(i);
                            }}
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </section>
              {lightBox && (
                <Lightbox
                  mainSrc={images[index]}
                  nextSrc={images[nextIndex]}
                  prevSrc={images[prevIndex]}
                  onCloseRequest={() => setLightbox(false)}
                  onMoveNextRequest={() => setIndex(nextIndex)}
                  onMovePrevRequest={() => setIndex(prevIndex)}
                />
              )}
            </>
          )}
        </JaLayout>
      )}
    </>
  );
};

export default CasePost;
